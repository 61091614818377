<template>
  <v-container class="pa-0">
    <v-row no-gutters>
      <v-col class="d-flex flex-column">
        <v-list-item class="d-flex align-end" style="background-color: transparent; position: relative; height: 200px; min-height: 90px; max-height: 200px; padding: 0 20px 10px 20px;">
          <v-list-item-action>
            <v-btn
              icon
              @click="disconnect"
            >
              <v-icon>mdi-logout</v-icon>
            </v-btn>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title v-text="profileName" />
            <v-list-item-subtitle v-text="address" />
          </v-list-item-content>
          <v-list-item-avatar width="88px" height="88px" :style="`background-image: url(` + mainHost + `/avatar?id=` + profileAvatar + `); bottom: -22px; border: 3px solid #fff; border-radius: 100%;  background-size: cover; background-position: center; z-index: 1;`" />
        </v-list-item>
      </v-col>
    </v-row>
    <v-row class="py-0 px-5 mt-0" style="background-color: transparent; height: 40px; min-height: 40px; max-height: 40px; position: relative;">
      <v-col class="d-flex" md="5">
        <small>YOUR WALLET</small>
      </v-col>
      <div class="d-flex align-center" @click="addToken">
        <span class="mr-1 font-weight-bold">{{ totalBalanceDisplay }}</span>
        <v-img
        class="mb-2"
        width="24"
        height="24"
        :src="require(`@/assets/wallet_icn.svg`)"
        alt="Ícone da Wallet"
        style="filter: invert(1);"
        />
      </div>
    </v-row>
    <v-row class="d-flex justify-center" style="background: transparent; padding: 8px 10px;">
      <v-col class="d-flex">
        <v-text-field
          v-model="friendLabel"
          class="pl-0"
          type="text"
          placeholder="Search Friends"
          dense
          single-line
        />
        <div class="d-flex align-center ma-5">
          <i class="las la-user-plus add" @click="addFriend" />
          <i class="las la-search searching" />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  // Utilities
  import {
    mapActions,
    mapState,
  } from 'vuex'

  export default {
    name: 'FriendsLoggedIn',
    data () {
      return {
        friendLabel: '',
      }
    },
    computed: {
      ...mapState('wallet', ['address', 'mainHost', 'profileName', 'profileAvatar', 'totalBalance']),
      totalBalanceDisplay () {
        const ether = 1000000000000000000
        var balance = this.totalBalance / ether
        if (balance > 1000000) return (balance / 1000000) + 'kk'
        if (balance > 1000) return (balance / 1000) + 'k'
        return balance
      },
    },
    watch: {
      totalBalance () {
        // doo
      },
    },
    methods: {
      addToken () {
        console.log('add toekn!')
        var provider = window.ethereum
        provider.request({
          method: 'wallet_watchAsset',
          params: {
            type: 'ERC20',
            options: {
              address: '0xc85E9Ed799F08813daf03B4A92ca43E1C7878f26',
              symbol: 'GGT',
              decimals: 18,
              image: 'https://goodgame.video/gg.png',
            },
          },
        })
      },
      ...mapActions('wallet', ['disconnect', 'sendMsg']),
      addFriend () {
        this.sendMsg(JSON.stringify({ msg: 'add_friend', addr: this.friendLabel }))
        this.friendLabel = ''
      },
    },
  }
</script>

<style>
:root {
  --main-color: #fd1268;
  --sub-color: #fff;
  --main-background-color: #111111;
  --sub-background-color: #212121;
}

.menu_profile_user {
    position: relative;
    height: 90px;
    min-height: 90px;
    max-height: 90px;
    padding: 0 20px 10px 20px;
    display: flex;
    align-items: flex-end;
}

.vava {
    bottom: -22px; border: 3px solid #fff; border-radius: 100%;  background-size: cover; background-position: center; z-index: 1;
    }
.money_wallet {
    display: flex;
    align-items: center;
    position: absolute; right: 120px; bottom: 10px; font-weight: bold;
}
.friends_filter {
    background: transparent linear-gradient(
90deg
, #fd126800 0%, #fd1268db 100%) 0% 0% no-repeat padding-box;
    padding: 8px 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 18px;
    height: 38px;
}
</style>
